@font-face {
  font-family: Calibre;
  src: url("./Assets/TestCalibre-Light.otf") format("opentype");
}

@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000');
body {
  /* margin: 0; */
  /* font-family: "DM Sans" !important; */
  /* font-family: "Roboto" !important; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-image: linear-gradient(to left, rgb(27 20 41), rgb(20 15 35)); */
}
