@font-face {
  font-family: Coconat;
  src: url("./Assets/Coconat-Regular.otf") format("opentype");
}

@font-face {
  font-family: Coconat;
  src: url("./Assets/Coconat-Demi.otf") format("opentype");
  font-weight: bold;
}

@font-face {
  font-family: Absans;
  src: url("./Assets/Absans-Regular.woff2") format("woff2");
}

html {
  --section-background-color: #000;
  /* --image-gradient: #fff; */
  --imp-text-color: #0008ff;
  --default-text: #000;
}

.purple {
  color: var(--imp-text-color) !important;
}

button:focus {
  box-shadow: none !important;
}

#link {
  color: red;
  text-decoration: none;
}

#link:hover {
  color: #0000ff;
  text-decoration: none;
}

/* --------- */
/*  Preloader */
/* --------- */
#preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999999;
  background-color: #fff;
  background-image: url(./Assets/pre.svg);
  background-repeat: no-repeat;
  background-position: center;
}

#preloader-none {
  opacity: 0;
}

#no-scroll {
  overflow: hidden;
  height: 100vh;
}

#descriptions {
  color: #4e4e4e !important;
}

/* --------- */
/* Navbar Section  */
/* --------- */
.sticky {
  background-color: transparent !important;
  transition: all 0.3s ease-out 0s !important;
  box-shadow: 0px 10px 10px 0px rgba(9, 5, 29, 0.171) !important;
  backdrop-filter: blur(15px) !important;
}

.navbar {
  position: fixed !important;
  transition: all 0.3s ease-out 0s !important;
  padding: 0.3rem 2rem !important;
  font-size: 1.2rem !important;
}

.navbar-toggler {
  position: relative !important;
  background-color: transparent !important;
  border-color: transparent !important;
}

.navbar-toggler span {
  display: block !important;
  background-color: #777777 !important;
  height: 4px !important;
  width: 27px !important;
  margin-top: 5px !important;
  margin-bottom: 5px !important;
  transform: rotate(0deg) !important;
  left: 0 !important;
  opacity: 1 !important;
}

.navbar-toggler:focus,
.navbar-toggler:active {
  outline: 0 !important;
}

.navbar-toggler span:nth-child(1),
.navbar-toggler span:nth-child(3) {
  transition: transform 0.35s ease-in-out !important;
  transition: transform 0.35s ease-in-out !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(1) {
  position: absolute !important;
  left: 12px !important;
  top: 10px !important;
  transform: rotate(135deg) !important;
  opacity: 0.9 !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(2) {
  height: 12px !important;
  visibility: hidden !important;
  background-color: transparent !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(3) {
  position: absolute !important;
  left: 12px !important;
  top: 10px !important;
  transform: rotate(-135deg) !important;
  opacity: 0.9 !important;
}

@media (max-width: 767px) {
  .navbar {
    padding: 1rem 2rem !important;
    font-size: 1.4rem !important;
    background-color: transparent !important;
  }

  .navbar-nav .nav-item a::after {
    display: none !important;
  }
}

.logo {
  padding-top: 0.8em;
  height: 3em !important;
}

.navbar-nav .nav-link {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.nav-link {
  padding: 0.8rem 1rem !important;
}

@media (max-width: 767px) {
  .nav-link {
    padding: 0.7rem 1rem !important;
  }
}

.navbar-nav .nav-item {
  position: relative;
  margin-left: 20px;
}

.navbar-nav .nav-item a {
  font-weight: 400;
  /* transition: all 0.3s ease-out 0s; */
  position: relative;
  z-index: 1;
}

.navbar-nav .nav-item a:hover {
  color: #00f;
}

/* --------- */
/* Home section */
/* --------- */

.home-header {
  padding-top: 80px !important;
}

.home-section {
  position: relative;
  z-index: 9;
  background-position: top center;
  background-repeat: no-repeat;
  padding-bottom: 30px !important;
  padding-top: 30px !important;
  /* height: 100vh; */
}

.home-content {
  padding: 9rem 0 2rem !important;
  text-align: left;
}

.home-section a {
  color: var(--imp-text-color) !important;
  text-decoration: none;
}

.home-section a:hover {
  color: #fff !important;
  background-color: var(--imp-text-color);
  box-shadow: 2px 0 0 var(--imp-text-color), -2px 0 0 var(--imp-text-color);
  padding: 1px 0;
  text-decoration: none;
}

.heading-name {
  font-size: 1.2em !important;
  padding-left: 25px !important;
  font-weight: 300;
}

.heading {
  font-size: 1.4em !important;
  padding-left: 25px !important;
  font-weight: 400;
}

.homelinks {
  font-size: 1.2em !important;
  padding-left: 25px !important;
  font-weight: 600;
  /* text-decoration: underline; */
}

.main-name {
  color: var(--imp-text-color);
}

.social-icons {
  display: inline-block !important;
  padding-right: 15px;
  padding-left: 15px;
  font-size: 1.2em;
}

/* --------- */
/* Footer */
/* --------- */
.footer {
  background-color: #adadad;
  bottom: 0 !important;
  padding-top: 10px !important;
  padding-bottom: 8px !important;
}

.footer-body {
  z-index: 1;
  text-align: right !important;
  /* background-color: #000; */
}

.footer-icons {
  margin-top: 0.5em !important;
  margin-bottom: 0.5em !important;
  text-align: right;
  padding: 0 !important;
}

/* --------- */
/* Projects */
/* --------- */
.project-section {
  position: relative !important;
  padding-top: 150px !important;
  padding-bottom: 30px !important;
  /* background-image: var(--section-background-color) !important; */
  text-align: left;
  background-color: #f9f8f6;
}

.project-card {
  padding-top: 50px !important;
  padding-bottom: 50px !important;
  padding-left: 25px !important;
  padding-right: 25px !important;
  height: auto !important;

}

.project-card-view {
  border-radius: 20px !important;
  color: var(--default-text) !important;
  background-color: #fff !important;
  opacity: 0.9 !important;
  transition: all 0.5s ease 0s !important;
  height: 100% !important;
}

.project-card-view:hover {
  /* transform: scale(1.02) !important; */
  overflow: hidden !important;
  /* border-color: #000; */
  background-color: #c7cbff6e !important;
}

.blog-card {
  padding-top: 50px !important;
  padding-bottom: 50px !important;
  padding-left: 25px !important;
  padding-right: 25px !important;
  height: auto !important;
}

.card-img-top {
  padding: 20px !important;
  opacity: 1 !important;
  border-radius: 10px !important;
}

.blog-img {
  padding: 0px !important;
  opacity: 0.8 !important;
  border-radius: 0px !important;
}

.btn-primary {
  color: #000 !important;
  background-color: #fff !important;
  border-color: #000 !important;
  border-radius: 20px !important;
}

.btn-primary:hover {
  color: #fff !important;
  background-color: var(--imp-text-color) !important;
  border-color: #000 !important;
}

.btn:focus {
  outline: none !important;
  box-shadow: none !important;
}

.project-heading {
  color: var(--default-text) !important;
  font-size: 2.3em !important;
  font-family: Absans;
  font-weight: bold !important;
  padding-top: 10px !important;
  text-transform: uppercase;
}

/* --------- */
/* About */
/* --------- */

.about-section {
  position: relative !important;
  padding-top: 150px !important;
  padding-bottom: 30px !important;
  background-image: var(--section-background-color) !important;
  color: var(--default-text) !important;
  text-align: left;
}

.about-section a {
  color: var(--imp-text-color) !important;
  text-decoration: none;
}

.about-section a:hover {
  color: #fff !important;
  background-color: var(--imp-text-color);
  box-shadow: 2px 0 0 var(--imp-text-color), -2px 0 0 var(--imp-text-color);
  padding: 1px 0;
  text-decoration: none;
}

.tech-icons {
  font-size: 4.5em !important;
  margin: 15px !important;
  padding: 10px !important;
  opacity: 0.93 !important;
  border: 1.7px solid rgba(200, 137, 230, 0.637) !important;
  vertical-align: middle !important;
  text-align: center !important;
  border-radius: 5px !important;
  display: table !important;
  box-shadow: 4px 5px 4px 3px rgba(89, 4, 168, 0.137) !important;
  overflow: hidden !important;
  transition: all 0.4s ease 0s !important;
}

@media (max-width: 767px) {
  .tech-icons {
    margin: 10px !important;
  }
}

.tech-icons:hover {
  transform: scale(1.05) !important;
  overflow: hidden !important;
  border: 2.2px solid rgba(197, 115, 230, 0.883) !important;
}

.tech-icon-images {
  padding: 20px !important;
  line-height: 1.6 !important;
}

.quote-card-view {
  border: none !important;
  color: var(--default-text) !important;
  background-color: transparent !important;
}

.about-activity {
  list-style: none !important;
  text-align: left !important;
  padding-left: 1px !important;
}

@media (max-width: 300px) {
  .about-img {
    padding-top: 0 !important;
  }
}